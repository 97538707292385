<template>
   <div class="container">
    <el-container class="wrap">
      <!-- 头部 -->
      <el-header >
        <Header />
      </el-header>
      <!-- 中间 -->
      <el-main class="mains">
       <div class="main_content flex-x-between">
          <div class="left">
            <p class="font-36 text-bold">参加模考</p>
            <p class="font-36 text-bold m-b-20">获取分数评估</p>
            <p class="font-18 c-8d">提前熟悉测试题型、模考大约需要 45 分钟</p>
            <p class="font-18 c-8d m-b-18">获取非正式分数评估</p>
            <p class="font-16 c-ff">*当前剩余模考次数 {{mockDetail.exam_number}} 次</p>
            <el-button class="mock" @click="goExam()">立即模考</el-button>
          </div>
          <div class="right">
            <img src="../assets/image/login_img.png" alt="">
          </div>
       </div>
       <!-- 模考记录 -->
       <div class="main_content recordBox p-20" v-if="mock_exam_paper_list.length > 0">
          <div class="record" v-for="(item,index) in mock_exam_paper_list" :key="index">
             <p class="font-16 c-8d">于{{item.create_time}}进行的考试</p>
             <p class="text-bold font-20" v-if="item.status == 0">{{item.status_name}}</p>
             <p class="text-bold font-20" v-else>{{item.check_score}}分</p>
             <div class="score" v-if="item.status==1" @click="checkScore(item.id)">查看成绩单</div>
          </div>
       </div>
       <!-- 空数据 -->
       <div class="main_content empty " v-if="mock_exam_paper_list.length == 0">
          <img src="../assets/image/empty.png" alt="">
          <span class="font-14">您还没有模考记录</span>
       </div>
      </el-main>
    </el-container>
   </div>
</template>
<script>

export default {
   data() {
      return {
        mockDetail: {
          exam_number: 0,
        },
        mock_exam_paper_list: []
      }
   },
   created() {
      this.getInfo()
   },
   methods: {
      goExam() {
        this.$router.push('/mock')
      },
      checkScore(id) {
        this.$router.push('/result?id='+id);
      },
      getInfo() {
        this.$api.getInfo({}).then(res => {
          if(res.code == 1) {
              this.mockDetail = res.data;
              this.mock_exam_paper_list = res.data.mock_exam_paper_list;
            }
        })
      },
   }
}
</script>
<style  lang="scss" scoped>
.container,.wrap {
  width: 100%;
  height: 100vh;
}
.el-header {
  height: 70px!important;
}

.mains {
  height: calc(100% - 70px);
  padding-top: 62px;
  box-sizing: border-box;
  background-image: url('../assets/image/bg.png');
  background-position: 100% 100%;
  background-repeat: no-repeat;
  
  .mock {
    width: 168px;
    height: 48px;
    border-radius: 2px;
    background: $color-title2;
    color: #fff;
    font-size: 16px;
    box-shadow: 0 8px 16.2px 0 #24008033;
    margin-top: 10px;
  }
  
  .right {
    width: 315px;
    height: 272px;
    img {
      width: 100%;
      height: 100%;
    }
  }
}

.empty {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 260px;
  background: #fff;
  border-radius: 2px;
  margin-top: 70px;
  img {
    width: 85px;
    height: 85px;
    margin-bottom: 20px;
  }
}

.recordBox {
  display: flex;
  flex-wrap: wrap;
  background: #fff;
  border-radius: 2px;
  margin-top: 70px;
  .record {
    position: relative;
    width: 275px;
    height: 106px;
    background: #F6F7FB;
    border-radius: 2px;
    padding: 20px;
    box-sizing: border-box;
    margin-right: 30px;
    margin-bottom: 30px;
      &:nth-child(4n) {
        margin-right: 0;
      }
    .score {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 83px;
      height: 33px;
      line-height: 33px;
      text-align: center;
      border-radius: 2px;
      background: #F4EFFF;
      font-size: 12px;
      color: $color-title2;
      cursor: pointer;
    }
  }
}
</style>
